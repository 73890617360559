import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  BellIcon,
  HeartIcon,
  LinkExternalIcon,
  PersonIcon,
  SearchIcon,
  SunIcon,
} from "@primer/octicons-react";
import { filtered } from "filtered";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { InstallButton, Search } from "../../components";
import Swal from "sweetalert2";
import articleList from "../../articles/list.json";
import pwalogo from "assets/images/pwa-logo.svg";
import "./home.css";

const ArticleCard = lazy(() => import("../../components/article-card"));
const renderLoader = () => <article className="post-item lazy-loder"></article>;

interface Article {
  title: string;
  desc: string;
  author: string;
  date_created: any;
  path: string;
  tags: string;
}

const HomePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hide, setHide] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [list, setList] = useState<Article[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [searching, setSearching] = useState(false);
  const [aside, setAside] = useState(false);
  const [slug, setSlug] = useState<any>(null);
  const [name, setName] = useState<any>(null);

  const greetName = name ? name : localStorage.getItem("read_name");

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    // Set Article list...
    if (articleList) setArticles(articleList);
  }, []);

  useEffect(() => {
    const query = searchParams.get("tag");
    if (query) {
      setSlug(query);
    } else {
      setSlug("");
    }
  }, [searchParams]);

  useEffect(() => {
    if (slug) {
      const result = articleList.filter(article =>
        article.tags.split(", ").includes(slug)
      );
      setArticles(result);
    }
  }, [slug]);

  const handleChange = useCallback(
    async (event: any) => {
      event.preventDefault();
      setSearching(true);
      const query = event.target.value;
      const result = filtered({
        data: articles,
        query: query,
        filter: "title",
        firestore: false,
      });
      if (result) setList(result);
    },
    [articles]
  );

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const height: number = window.scrollY;
      height >= 193 ? setAside(true) : setAside(false);
    });
  }, []);

  const greetings = (caps = false) => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 12) {
      return caps ? "Good Morning" : "Good morning";
    } else if (curHr < 18) {
      return caps ? "Good Afternoon" : "Good afternoon";
    } else {
      return caps ? "Good Evening" : "Good evening";
    }
  };

  const handleName = async () => {
    const { value } = await Swal.fire({
      title: "Aha, your first name?",
      input: "text",
      showCancelButton: true,
    });
    if (value) {
      setName(value);
      localStorage.setItem("read_name", value);
    }
  };

  const itemList = searching ? list : articles;

  return (
    <section>
      <section className="app-top-blurb">
        <div style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
          {" "}
          <span>
            {greetName
              ? `Hey 👋, ${greetName}. Change your salute`
              : "Personalized greetings? Tell me your first name!"}
          </span>
          <a
            style={{ marginLeft: 4 }}
            onClick={event => {
              event.preventDefault();
              handleName();
            }}
            href="/">
            {" "}
            here.
          </a>
        </div>
      </section>
      <section className="home-page">
        <nav>
          <div
            style={!hide && mobile ? { display: "none" } : {}}
            className="write-story">
            <Link
              className="submit-cta"
              to="/docs/submit-an-article-to-pwafire/">
              Submit a doc
            </Link>
          </div>
          <h1
            style={hide ? {} : { display: "none" }}
            className="main heading fadein">
            <img loading="eager" width={55} src={pwalogo} alt="PWAs" />
          </h1>
          <Search
            style={hide ? { display: "none" } : {}}
            handleChange={handleChange}
          />
          <ul className="nav-icons">
            <li className="nav-icon" onClick={() => setHide(prev => !prev)}>
              <SearchIcon />
            </li>
            <li
              className="nav-icon"
              onClick={() => window.open("https://twitter.com/pwafire")}>
              <BellIcon />
            </li>
            <li
              className="fa_user nav-icon"
              onClick={() =>
                window.open("https://github.com/pwafire/pwafire/discussions")
              }>
              <PersonIcon />
            </li>
          </ul>
        </nav>
        <div className="label-container fadein">
          <span className="label label-success">
            {greetName ? `${greetings()}, ${greetName}` : greetings(true)}
          </span>
        </div>
        <main>
          <article>
            <section className="section1-heading-nav">
              <h2 className="home-title">
                {" "}
                <SunIcon size={24} />
                <div style={{ marginLeft: 10 }}>
                  {" "}
                  {slug ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Latest{" "}
                      <span
                        style={{
                          marginLeft: 10,
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="slug">
                        #{slug}
                      </span>
                    </span>
                  ) : (
                    "Latest"
                  )}
                </div>
              </h2>
              <div className="nav-more">
                <a
                  href="https://twitter.com/mayeedwin1"
                  target="_blank"
                  rel="noreferrer">
                  <span style={{ marginRight: 5 }}>Social</span>
                  <HeartIcon size={16} />
                </a>
              </div>
            </section>
            <section
              style={slug ? {} : { display: "none" }}
              className="home-page-back-cta">
              <a
                href="/"
                onClick={event => {
                  event.preventDefault();
                  setArticles(articleList);
                  navigate("/");
                }}>
                {" "}
                <ArrowLeftIcon />{" "}
                <span style={{ marginLeft: 8 }}>All Articles</span>
              </a>
            </section>
            <section className="article-content">
              <section style={{ display: "block" }} className="posts-container">
                {itemList.length === 0 ? (
                  <div>Not results found</div>
                ) : (
                  itemList.map(
                    (item: {
                      path: string;
                      author: string;
                      title: string;
                      desc: string;
                      date_created: string;
                      tags: string;
                    }) => (
                      <Suspense fallback={renderLoader()} key={item.path}>
                        <ArticleCard
                          data={item}
                          slug={slug}
                          query={slug}
                          setSlug={setSlug}
                        />
                      </Suspense>
                    )
                  )
                )}
              </section>
              <aside className="article-aside">
                <section style={aside ? { position: "sticky", top: 50 } : {}}>
                  <section className="article-aside-inner fadein">
                    <div className="article-aside-header">
                      What's the Read Blog?
                    </div>
                    <div className="article-aside-content">
                      <p>
                        The Read Blog is an <b>effort to</b> create a pool of
                        developer technical content. Built by{" "}
                        <a
                          className="link-external"
                          href="https://maye.pwafire.org"
                          target="_blank"
                          rel="noreferrer">
                          <span style={{ marginRight: 2 }}>Maye Edwin</span>
                        </a>{" "}
                        and powered by{" "}
                        <a
                          className="link-external"
                          href="https://pwafire.org"
                          target="_blank"
                          rel="noreferrer">
                          <span style={{ marginRight: 2 }}>pwafire.org</span>
                          <LinkExternalIcon />
                        </a>
                      </p>
                    </div>
                  </section>
                  <InstallButton />
                </section>
              </aside>
            </section>
          </article>
          {/* <Footer /> */}
        </main>
      </section>
    </section>
  );
};

export default HomePage;
