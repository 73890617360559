import { useEffect, useState } from "react";
import { DownloadIcon } from "@primer/octicons-react";
import { pwa } from "pwafire";
import pwalogo from "assets/images/pwa-logo.svg";
import "./install.css";

const InstallButton = () => {
  // States..
  const [isInstalled, setIsInstalled] = useState(true);
  const [savedDefferedPrompt, setSavedDefferedPrompt] = useState<any>(null);
  // Side effect on component mount...
  useEffect(() => {
    // 1. Check if app was installed...
    pwa.Install("installed", () => {
      // b) => Hide the app-provided install promotion
      setIsInstalled(true);
      // c) => Clear the deferredPrompt so it can be garbage collected
      setSavedDefferedPrompt(null);
      // d) => Optionally, send analytics event to indicate successful install
    });

    // 2. Before install prompt is shown...
    pwa.Install("before", (event) => {
      // Prevent the mini-infobar from appearing on desktop...
      if (window.matchMedia("(min-width: 767px)").matches) {
        event.preventDefault();
      }
      // a) => Stash the event so it can be triggered later.
      setSavedDefferedPrompt(event);
      // b) => Update UI notify the user they can install the PWA
      setIsInstalled(false);
      // c) => Optionally, send analytics event that PWA install promo was shown.
    });
  }, []);

  // Handle install click...
  const handleInstall = async () => {
    // 3. Show the install prompt.
    pwa.Install("install", async (event: string) => {
      // Event type is install
      console.log(event);
      // a) => Show the install prompt
      savedDefferedPrompt.prompt();
      // b) =>  Wait for the user to respond to the prompt
      const { outcome } = await savedDefferedPrompt.userChoice;
      if (outcome === "accepted") {
        // c, i) =>  Optionally, send analytics event with outcome of user choice
      } else {
        // c, ii) => Optionally, send analytics event with outcome of user choice
      }
      // d) => We've used the prompt, and can't use it again, throw it away
      setSavedDefferedPrompt(null);
    });
  };

  // Render the button...
  return isInstalled ? null : (
    <div className="app-custom-install">
      <div className="app-custom-install-info">
        <img className="app-custom-install-img" src={pwalogo} alt="PWA Logo" />
        <div className="app-custom-install-text">
          Install Desktop Application
        </div>
      </div>
      <button className="app-custom-install-btn" onClick={handleInstall}>
        <DownloadIcon />
        <span>Install</span>
      </button>
    </div>
  );
};

export default InstallButton;
