import { Link, useNavigate } from "react-router-dom";
import athourAvatar from "assets/images/user.svg";
interface ArticleCardInterface {
  data: {
    author: string;
    title: string;
    desc: string;
    date_created: string;
    path: string;
    tags: string;
  };
  slug: string;
  setSlug: (slug: string) => void;
  query: any;
}

const ArticleCard = ({
  data,
  slug,
  query = "",
  setSlug,
}: ArticleCardInterface) => {
  const navigation = useNavigate();
  const { author, date_created, path, title, desc, tags } = data;

  const dateString = new Date(date_created).toLocaleDateString(undefined, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <article className="post-item">
      <div className="post-item-date">
        <Link to="/">
          <time dateTime={dateString}>{dateString}</time>
        </Link>
      </div>

      <div className="post-item-content">
        <ul className="post-item-categories">
          {tags.split(", ").map((tag: string) => (
            <li
              onClick={() => {
                navigation({
                  pathname: "/",
                  search: "?" + new URLSearchParams({ tag: tag }).toString(),
                });

                setSlug(tag);
              }}
              className={
                slug === tag.toLocaleLowerCase().trim() && query
                  ? `selected-tag label-success`
                  : "label-success"
              }
              key={tag}>
              {tag.toLocaleLowerCase().trim()}
            </li>
          ))}
        </ul>

        <h4 className="post-item-title">
          <a href={`/docs/${path}/`}>{title}</a>
        </h4>

        <div className="post-item-excerpt">
          <p>{desc}</p>
        </div>

        <a href="/" className="author-block">
          <div className="author-block-pic">
            <img
              alt={author}
              src={athourAvatar}
              className="author-avatar"
              height={35}
              width={35}
              itemProp="image"
            />{" "}
          </div>
          <p className="post-author-name">{author}</p>
        </a>
      </div>
    </article>
  );
};

export default ArticleCard;
