import { CSSProperties } from "react";
import { SearchIcon } from "@primer/octicons-react";
import "./search.css";

const Search = ({
  placeHolder = "Search...",
  style = {},
  handleChange = () => null,
}: {
  placeHolder?: string;
  style?: CSSProperties;
  handleChange?: any;
}) => {
  return (
    <span style={style} className="search-container fadein">
      <span className="search-icon">
        <SearchIcon />
      </span>
      <input
        type="search"
        className="search-input"
        placeholder={placeHolder}
        onChange={handleChange}
      />
    </span>
  );
};

export default Search;
